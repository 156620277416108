import { css } from '@emotion/react'
import type { JobRunnerConfig } from '@orus.eu/backend/src/commands/run-job/config'
import { TechnicalError } from '@orus.eu/error'
import { Button, sectionDelimitersBorder, spacing, useCrash, useEnqueueToast } from '@orus.eu/pharaoh'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { trpcReact } from '../../../../../client'
import { ensureMonacoWorkerInitialized } from '../platform-editor/monaco-worker'

export const JobEditor = memo(function JobEditor() {
  const crash = useCrash()
  const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor | null>(null)
  const monacoEl = useRef<HTMLDivElement | null>(null)
  const { enqueueToast } = useEnqueueToast()
  const runEphemeralJobMutation = trpcReact.jobs.runEphemeralJob.useMutation()

  const run = useCallback(() => {
    if (!editor) {
      enqueueToast('Unexpected error: editor not initialized', { variant: 'danger' })
      return
    }

    const config = JSON.parse(editor.getValue())
    runEphemeralJobMutation.mutate({ config })
  }, [editor, runEphemeralJobMutation, enqueueToast])

  useEffect(() => {
    if (monacoEl) {
      setEditor((editor) => {
        if (editor) return editor

        const currentMonacoEl = monacoEl.current
        if (!currentMonacoEl) {
          crash({ type: 'unexpected-error', err: new TechnicalError('expected monacoEl.current to be set') })
          return null
        }

        ensureMonacoWorkerInitialized()

        const newEditor = monaco.editor.create(currentMonacoEl, {
          value: '', // content loaded asynchronously from server
          language: 'json',
          automaticLayout: true,
        })

        newEditor.setValue(JSON.stringify(INITIAL_JOB_CONFIG, null, 2))

        return newEditor
      })
    }

    return () => editor?.dispose()
  }, [crash, editor])

  useEffect(() => {
    if (runEphemeralJobMutation.status === 'error') {
      console.error('Error while running the job', runEphemeralJobMutation.error)
      enqueueToast('Failed to run job', { variant: 'danger' })
    }

    if (runEphemeralJobMutation.status === 'success') {
      enqueueToast('Job ran successfully', { variant: 'success' })
    }
  }, [runEphemeralJobMutation, enqueueToast])

  return (
    <>
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            flex-grow: 0;
            flex-shrink: 0;
            padding: 0 ${spacing[60]} ${spacing[40]} ${spacing[60]};
            display: flex;
            gap: ${spacing[40]};
          `}
        >
          <Button
            icon="play-solid"
            size="small"
            avatarPosition="left"
            variant="primary"
            onClick={run}
            isLoading={runEphemeralJobMutation.isPending}
          >
            Exécuter
          </Button>
        </div>
        <div
          css={css`
            flex-grow: 1;
            flex-shrink: 1;
            border-top: ${sectionDelimitersBorder};
          `}
          ref={monacoEl}
        />
      </div>
    </>
  )
})

const INITIAL_JOB_CONFIG: JobRunnerConfig = {
  type: 'generate-report',
  reportType: 'internal-bi-contract-generic',
  requesterEmail: 'bo-extracts-recipients@orus.eu',
  extractDate: 'today',
  gzip: true,
}
